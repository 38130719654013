import React from 'react';
import { Modal } from 'antd';
import { translate } from '~/services/i18n';

const PreviewImageModal = ({
  title,
  visible = false,
  onOk,
  onCancel,

  src,
  alt,
  height,
  width,
}: any) => {
  return (
    <div className="preview-image-modal">
      <Modal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText={translate('COMPONENTS.MODAL.OK.LABEL')}
        cancelText={translate('COMPONENTS.MODAL.CANCEL.LABEL')}
      >
        <img 
          src={src}
          alt={alt}
          height={height}
          width={width}
        />
      </Modal>
    </div>
  );
}

export default PreviewImageModal;
import getInstance from './instance';

const ProductApi = {
  getReport: async () => {
    const instance = await getInstance();
    const { data } = await instance.get('/product');

    return data;
  },
};

export default ProductApi;

import {
  BANNER_COUNT,
  BANNER_DETAIL,
  BANNER_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.BannerReducer = {
  detail: null,
  list: [],
  listCount: 0,
  total: 0,
};

const bannerReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case BANNER_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case BANNER_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    case BANNER_COUNT:
      state = {
        ...state,
        total: action.payload.count,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default bannerReducer;
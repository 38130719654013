import { translate } from '~/services/i18n';
import { POSTER_CONTENT_TYPE } from '~/enum/poster';

export const getPosterContentTypeOptions = () => ([
  {
    name: translate('PAGES.PANEL.POSTER.CONTENT.OPTIONS.WEBVIEW'),
    value: POSTER_CONTENT_TYPE.WEBVIEW,
  },
  {
    name: translate('PAGES.PANEL.POSTER.CONTENT.OPTIONS.COLLECTION'),
    value: POSTER_CONTENT_TYPE.COLLECTION,
  },
  {
    name: translate('PAGES.PANEL.POSTER.CONTENT.OPTIONS.PRODUCT'),
    value: POSTER_CONTENT_TYPE.PRODUCT,
  },
]);

export const getCollectionOptions = (collections: Array<models.Collection>) => {
  return collections.map((o) => {
    return {
      name: `${o.title} (${o.handle})`,
      value: o.handle
    };
  });
};

export const getProductOptions = (products: Array<models.Product>) => {
  return products.map((o) => {
    return {
      name: `${o.title} (${o.handle})`,
      value: o.handle,
    };
  });
};
import { SaveOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import * as PushNotificationActions from '~/actions/pushNotification';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedCheckbox from '~/components/AdvancedCheckbox/AdvancedCheckbox';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { getRouteStackPath } from '~/config/routes';
import { PAGE_TYPE } from '~/enum/pageType';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';
import { maskDateTime } from '~/services/masks';

const formInitialValues: models.PushNotification = {
  title: '',
  body: '',
  sendDate: '',
  isSchedule: false,
};

const PushNotificationDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);

  const { pathname } = useLocation();
  const { pushNotification } = useReduxState();

  const onFormChange = (key: string, val: string | boolean | null) => {
    setForm((prevState: models.PushNotification) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (pushNotification && pushNotification.detail) {
      setForm({
        ...pushNotification.detail,
        sendDate: DateTime.fromISO(pushNotification.detail.sendDate as string).toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS
        )
      });
    } else {
      setForm(formInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushNotification.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(PushNotificationActions.cleanPushNotificationDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  const onFormSubmit = () => {
    const requestForm = {
      title: form.title,
      body: form.body,
      sendDate: form.sendDate !== '' 
        ? DateTime.fromFormat(form.sendDate as string, 'dd/MM/yyyy hh:mm').toJSDate()
        : null
    };

    if (!form.title) {
      MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.TITLE');
    } else if (form.isSchedule && !form.sendDate) {
      MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.SENDDATE');
    } else if (!form.body) {
      MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.BODY');
    } else {
      dispatch(PushNotificationActions.addPushNotification(requestForm));
    }
  };

  return (
    <div className="push">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.PUSH.REPORT.TITLE'),
                url: getRouteStackPath('NOTIFICATION', 'REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.PUSH.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="push__details__form">
            <div className="push__details__form__title">
              <h3 className="push__details__form__title__text">
                {translate('PAGES.PANEL.PUSH.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SUBJECT.LABEL'
                    )}
                    value={form.title}
                    onChange={(val: string | null) => onFormChange('title', val)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                    <AdvancedCheckbox
                      label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.IS_SCHEDULE.LABEL')}
                      value={form.isSchedule}
                      onChange={(val: boolean) => {
                        onFormChange('isSchedule', val);
                        if (!val) { onFormChange('sendDate', null); }
                      }}
                      disabled={pageType === PAGE_TYPE.EDIT}
                    />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    onChange={(value: string) =>
                      onFormChange('sendDate', value)
                    }
                    value={maskDateTime(form.sendDate as string)}
                    label={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SENDDATE.LABEL'
                    )}
                    placeholder={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SENDDATE.PLACEHOLDER'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT || !form.isSchedule}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.BODY.LABEL'
                    )}
                    value={form.body}
                    onChange={(val: string | null) =>
                      onFormChange('body', val)
                    }
                    multiline
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Link to={getRouteStackPath('PUSH', 'REPORT')}>
                    <AdvancedButton
                      className="push__advanced-button"
                      text={translate(
                        'PAGES.PANEL.PUSH.DETAILS.FORM.BACK.LABEL'
                      )}
                    />
                  </Link>
                </Col>
                <Col md={4}>
                  <AdvancedButton
                    type="submit"
                    className="push__advanced-button"
                    text={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PushNotificationDetails;

import {
  COUNTRY_DETAIL,
  COUNTRY_REPORT,
  COUNTRY_COUNT,
} from '~/actions/actionTypes';

const initialState: reducers.CountryReducer = {
  detail: null,
  list: [],
  listCount: 0,
  total: 0,
};

const countryReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case COUNTRY_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case COUNTRY_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    case COUNTRY_COUNT:
      state = {
        ...state,
        total: action.payload.count,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default countryReducer;
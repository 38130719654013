import { SaveOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import * as CategoryActions from '~/actions/category';
import * as CollectionActions from '~/actions/collection';
import * as ProductActions from '~/actions/product';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedSelect from '~/components/AdvancedSelect/AdvancedSelect';
import AdvancedAutoComplete from '~/components/AdvancedAutoComplete/AdvancedAutoComplete';
import AdvancedUploader from '~/components/AdvancedUploader/AdvancedUploader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { PAGE_TYPE } from '~/enum/pageType';
import { POSTER_TYPE, POSTER_CONTENT_TYPE } from '~/enum/poster';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';
import {
  getPosterContentTypeOptions,
  getCollectionOptions,
  getProductOptions
} from '~/utils/selectOptions';

const formInitialValues: models.Poster = {
  title: '',
  picUrl: '',
  type: POSTER_TYPE.CATEGORY,
  content: '',
  contentType: POSTER_CONTENT_TYPE.WEBVIEW,
  picture: '',
  order: 1,
};

const rangeInitialValues: models.Range = {
  min: 1,
  max: 1,
};

const CategoryDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);
  const [range, setRange] = useState(rangeInitialValues);

  const { pathname } = useLocation();
  const { category, collection, product } = useReduxState();

  const renewContentOptions = (contentType: number) => {
    if (contentType === POSTER_CONTENT_TYPE.COLLECTION
      && collection.list.length === 0) {
      dispatch(CollectionActions.getCollectionReport());
    }

    if (contentType === POSTER_CONTENT_TYPE.PRODUCT
      && product.list.length === 0) {
      dispatch(ProductActions.getProductReport());
    }
  };

  const onContentTypeChange = (val: string | boolean | number | null) => {
    onFormChange('contentType', val);
    onFormChange('content', formInitialValues.content);
  };

  const onFormChange = (key: string, val: string | boolean | number | null) => {
    setForm((prevState: models.Poster) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (category && category.detail) {
      renewContentOptions(category.detail.contentType);
      setForm(category.detail);
      setRange({ min: 1, max: category.total });
    } else {
      setForm(formInitialValues);
      setRange({ min: 1, max: category.total + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(CategoryActions.cleanCategoryDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    renewContentOptions(form.contentType);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.contentType]);

  const onFormSubmit = () => {
    const requestForm: any = {
      title: form.title,
      picture: form.picture,
      type: form.type,
      content: form.content,
      contentType: form.contentType,
      order: form.order,
    };

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.ERROR.TITLE');
    }

    if (!form.picture && pageType !== PAGE_TYPE.EDIT) {
      return MessageService.error('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.ERROR.PICURL');
    }

    if (!form.content) {
      return MessageService.error('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.ERROR.CONTENT');
    }

    if (!form.contentType) {
      return MessageService.error('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.ERROR.CONTENT_TYPE');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CategoryActions.updateCategory(category?.detail?.id as string, requestForm));
    } else {
      dispatch(CategoryActions.addCategory(requestForm));
    }
  };

  return (
    <div className="category">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.POSTER.CATEGORY.REPORT.TITLE'),
                url: getRouteStackPath('POSTER', 'REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.POSTER.CATEGORY.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="category__details__form">
            <div className="category__details__form__title">
              <h3 className="category__details__form__title__text">
                {translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.SUBJECT.LABEL'
                    )}
                    value={form.title}
                    onChange={(val: string | null) =>
                      onFormChange('title', val)
                    }
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.ORDER.LABEL'
                    )}
                    onChange={(val: number) => onFormChange('order', val)}
                    value={form.order}
                    type='number'
                    inputProps={{ min: range.min, max: range.max }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedSelect
                    label={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.CONTENT_TYPE.LABEL')}
                    onChange={(val: number) => onContentTypeChange(val)}
                    value={form.contentType}
                    options={getPosterContentTypeOptions()}
                  />
                </Col>
                <Col md={4}>
                  {form.contentType === POSTER_CONTENT_TYPE.WEBVIEW && (
                    <AdvancedInput
                      label={translate(
                        'PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.CONTENT.WEBVIEW.LABEL'
                      )}
                      value={form.content}
                      onChange={(val: string | null) =>
                        onFormChange('content', val)
                      }
                    />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.COLLECTION && collection.list.length && (
                    <AdvancedAutoComplete
                      value={form.content}
                      label={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.CONTENT.COLLECTION.LABEL')}
                      options={getCollectionOptions(collection.list)}
                      onChange={(val: string | null) => onFormChange('content', val)}
                    />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.PRODUCT && product.list.length && (
                    <AdvancedAutoComplete
                      value={form.content}
                      label={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.CONTENT.PRODUCT.LABEL')}
                      options={getProductOptions(product.list)}
                      onChange={(val: string | null) => onFormChange('content', val)}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <label>{translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.PICURL.LABEL')}</label>
                  <AdvancedUploader
                    onChange={(val: models.File) => onFormChange('picture', val.base64)}
                    value={form.picture}
                    defaultImage={form.picUrl || undefined}
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.UPLOAD.LABEL')}
                      endIcon={<CloudUploadOutlined />}
                    />
                  </AdvancedUploader>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Link to={getRouteStackPath('POSTER', 'REPORT')}>
                    <AdvancedButton
                      className="category__advanced-button"
                      text={translate(
                        'PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.BACK.LABEL'
                      )}
                    />
                  </Link>
                </Col>
                <Col md={4}>
                  <AdvancedButton
                    type="submit"
                    className="category__advanced-button"
                    text={translate(
                      'PAGES.PANEL.POSTER.CATEGORY.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CategoryDetails;

/* tslint:disable:object-literal-sort-keys */
const routes: models.route[] = [
  {
    id: 'DASHBOARD',
    name: 'Dashboard',
    route: '/dashboard',
    defaultRoute: '/relatorio/detalhes',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/relatorio/detalhes',
      },
    ],
  },
  {
    id: 'ADMINISTRATOR',
    name: 'Administradores',
    route: '/administrador',
    defaultRoute: '/relatorio/lista',
    icon: '/assets/svg/panel-sidebar/ic_users.svg',
    iconAlt: 'Administradores',
    items: [
      {
        id: 'REPORT',
        name: 'Relatório',
        route: '/relatorio/lista',
      },
      {
        id: 'REGISTER',
        name: 'Registro',
        route: '/relatorio/registro',
      },
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/relatorio/detalhes/:id?',
      }
    ]
  },
  {
    id: 'POSTER',
    name: 'Home do Aplicativo',
    route: '/poster',
    defaultRoute: '/relatorio/lista',
    icon: '/assets/svg/panel-sidebar/ic_banner.svg',
    iconAlt: 'Home do Aplicativo',
    items: [
      {
        id: 'REPORT',
        name: 'Relatório',
        route: '/relatorio/lista',
      },
      {
        id: 'REGISTER_BANNER',
        name: 'Registro',
        route: '/relatorio/banner/registro',
      },
      {
        id: 'DETAILS_BANNER',
        name: 'Detalhes',
        route: '/relatorio/banner/detalhes/:id?',
      },
      {
        id: 'REGISTER_CATEGORY',
        name: 'Registro',
        route: '/relatorio/categoria/registro',
      },
      {
        id: 'DETAILS_CATEGORY',
        name: 'Detalhes',
        route: '/relatorio/categoria/detalhes/:id?',
      },
      {
        id: 'REGISTER_COUNTRY',
        name: 'Registro',
        route: '/relatorio/pais/registro',
      },
      {
        id: 'DETAILS_COUNTRY',
        name: 'Detalhes',
        route: '/relatorio/pais/detalhes/:id?',
      }
    ],
  },
  {
    id: 'PUSH',
    name: 'Push Notifications',
    route: '/push',
    defaultRoute: '/relatorio/lista',
    icon: '/assets/svg/panel-sidebar/ic_bell.svg',
    iconAlt: 'Push Notifications',
    items: [
      {
        id: 'REPORT',
        name: 'Relatório',
        route: '/relatorio/lista',
      },
      {
        id: 'REGISTER',
        name: 'Registro',
        route: '/relatorio/registro',
      },
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/relatorio/detalhes/:id?',
      }
    ],
  }
];

export const getRoutes = (): models.route[] => routes;

export const getRoutestack = (route: string): models.route =>
  routes.find((o) => o.route === route) as models.route;

export const getStackPath = (stackId: string): string => {
  return `${routes.find((o) => o.id === stackId)?.route}`;
};

export const routeExist = (route: string): boolean => {
  const routeTop = routes.find((o) => route.startsWith(o.route));

  if (!routeTop) {
    return false;
  }
  if (routeTop.route.length === route.length) {
    return false;
  }

  return (
    (routeTop.items.find((o) => `${routeTop.route}${o.route}` === route) &&
      true) ||
    false
  );
};

export const getRouteStackPath = (stackId: string, routeId: string): string => {
  const route = routes.find((o) => o.id === stackId);

  return `${route?.route}${route?.items.find((o) => o.id === routeId)?.route}`;
};

import PosterRequests from '~/repositories/poster';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { BANNER_DETAIL, BANNER_REPORT, BANNER_COUNT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanBannerDetail = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: BANNER_DETAIL,
  });
};

export const getBannerCount = (
  searchParams: advancedFilterModels.PosterAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: any = await PosterRequests.getCount(searchParams);
    dispatch({
      payload,
      type: BANNER_COUNT,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getBannerReport = (
  searchParams: advancedFilterModels.PosterAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Poster> = await PosterRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );
    dispatch({
      payload,
      type: BANNER_REPORT,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getBannerDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Poster = await PosterRequests.getDetail(id);

    dispatch({
      payload,
      type: BANNER_DETAIL,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateBanner = (id: string, params: models.Poster) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await PosterRequests.update(id, params);

    MessageService.success('PAGES.PANEL.POSTER.BANNER.DETAILS.SUCCESS_EDIT_MESSAGE');

    centralNavigationService.back();

  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const addBanner = (params: models.Poster) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await PosterRequests.add(params);

    MessageService.success('PAGES.PANEL.POSTER.BANNER.DETAILS.SUCCESS_ADD_MESSAGE');

    centralNavigationService.back();
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeBanner = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    await PosterRequests.remove(id);
    MessageService.success('PAGES.PANEL.POSTER.BANNER.DETAILS.SUCCESS_REMOVE_MESSAGE');
  }catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

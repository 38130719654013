import { Upload } from 'antd';
import React, { useState } from 'react';

import { compressImages } from '@portal/utils/imageUtils';
import { getBase64 } from '@portal/utils/string';
import * as MessageService from '~/services/message';

const AdvancedUploader = ({
  disabled,
  children,
  multiple,
  onPreview,
  defaultImage,
  className,
  uploadClassName,
  type,
  onChange,
  value,
  hidePreview,
}: any) => {

  const onFileChange = async ({ file }: any) => {
    if (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

      if(!isJpgOrPng && type !== 'image'){
        return MessageService.error('COMPONENTS.ADVANCED_FILE_UPLOAD.ERROR');
      }
      if (type === 'image'){
        file.base64 = await compressImages([file]);
      } else {
        file.base64 = await getBase64(file);
      }

      file.extension = file.name.split('.').pop();
      onChange(file);
      toggleFilePreview(file ?  file.base64 : value || null);

    } else {
      onChange(null);
    }
  };

  const toggleFilePreview = (file: any) => {
    setFilePreview(file);
  };

  const [filePreview, setFilePreview] = useState(false);

  return (
    <div className={className || "advanced-file-upload"}>
      <Upload
        className={uploadClassName || "advanced-file-upload__upload"}
        customRequest={onFileChange}
        disabled={disabled}
        multiple={multiple}
        onPreview={onPreview || (() => {})}
      >
        {(filePreview || defaultImage) && !hidePreview && (
          <div className="advanced-file-upload__preview">
            <img
              className="advanced-file-upload__preview__img"
              src={filePreview ? value : defaultImage}
              alt="preview"
            />
          </div>
        )}
          {children}
        </Upload>
    </div>
  );
};

export default AdvancedUploader;

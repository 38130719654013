import React from 'react';
import { Divider } from 'antd';
import { Row } from 'react-bootstrap';

import BannerReport from '~/pages/Panel/Poster/Report/Banner/BannerReport';
import CategoryReport from '~/pages/Panel/Poster/Report/Category/CategoryReport';
import CountryReport from '~/pages/Panel/Poster/Report/Country/CountryReport';

const PosterReport: React.FC = () => {
  return (
    <div className="poster">
      <Row className="poster__banner">
        <BannerReport />
      </Row>

      <Divider />

      <Row className="poster__category">
        <CategoryReport />
      </Row>

      <Divider />

      <Row className="poster__country">
        <CountryReport />
      </Row>
    </div>
  );
}

export default PosterReport;
import {
  PUSH_NOTIFICATION_DETAIL,
  PUSH_NOTIFICATION_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.PushNotificationReducer = {
  detail: null,
  list: [],
  listCount: 0,
};

const pushNotificationReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case PUSH_NOTIFICATION_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case PUSH_NOTIFICATION_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default pushNotificationReducer;
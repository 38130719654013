import getInstance from './instance';

const PosterApi = {
  getReport: async (params: advancedFilterModels.PosterAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/poster', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/poster/${id}`);

    return data;
  },

  getCount: async (params: advancedFilterModels.PosterAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/poster/count', { params });

    return data;
  },

  add: async (params: models.Poster) => {
    const instance = await getInstance();
    const { data } = await instance.post('/poster', params);

    return data;
  },

  update: async (id: string, params: models.Poster) => {
    const instance = await getInstance();
    const { data } = await instance.put(`/poster/${id}`, params);
    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/poster/${id}`);

    return data;
  },
};

export default PosterApi;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import PosterReport from '~/pages/Panel/Poster/Report/PosterReport';
import BannerDetails from '~/pages/Panel/Poster/Details/Banner/BannerDetails';
import CategoryDetails from '~/pages/Panel/Poster/Details/Category/CategoryDetails';
import CountryDetails from '~/pages/Panel/Poster/Details/Country/CountryDetails';

const PosterNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('POSTER', 'REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <PosterReport />
        </PanelContent>
      </Route>

      <Route path={getRouteStackPath('POSTER', 'REGISTER_BANNER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.BANNER.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.BANNER.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <BannerDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('POSTER', 'DETAILS_BANNER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.BANNER.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.BANNER.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <BannerDetails />
        </PanelContent>
      </Route>

      <Route path={getRouteStackPath('POSTER', 'REGISTER_CATEGORY')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.CATEGORY.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <CategoryDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('POSTER', 'DETAILS_CATEGORY')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.CATEGORY.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <CategoryDetails />
        </PanelContent>
      </Route>

      <Route path={getRouteStackPath('POSTER', 'REGISTER_COUNTRY')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.CATEGORY.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.CATEGORY.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <CountryDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('POSTER', 'DETAILS_COUNTRY')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.POSTER.COUNTRY.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.POSTER.COUNTRY.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <CountryDetails />
        </PanelContent>
      </Route>
      
    </Switch>
  );
};

export default PosterNavigationStack;

import getInstance from './instance';

const CollectionApi = {
  getReport: async () => {
    const instance = await getInstance();
    const { data } = await instance.get('/collection');

    return data;
  },
};

export default CollectionApi;

export const POSTER_TYPE: any = {
  BANNER: 1,
  CATEGORY: 2,
  COUNTRY: 3,
};

export const POSTER_CONTENT_TYPE: any = {
  WEBVIEW: 1,
  COLLECTION: 2,
  PRODUCT: 3,
};
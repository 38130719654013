import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import analytics from '~/services/analytics';

export interface IProps {
  routes: models.route[];
}

const PanelSidebarMenu: React.FC<IProps> = (props: IProps) => {

  const location = useLocation();
  React.useEffect(() => {
    analytics.webPageView(location.pathname);
  }, [location]);

  const isActive = (path: string) => location.pathname.includes(path);

  return (
    <div className="panel-sidebar-menu">
      <div className="panel-sidebar-menu__items">
        {props.routes.map((item: models.route, itemKey: number) => (
          <div
            key={itemKey.toString()}
            className="panel-sidebar-menu__items__single"
          >
            <Link
              to={`${item.route}${item.defaultRoute}`}
              className={`
                panel-sidebar-menu__items__single__link
                ${isActive(`${item.route}`) ? 'panel-sidebar-menu__items__single__link--active' : ''}
              `}
            >
              <span className="panel-sidebar-menu__items__single__icon">
                <img
                  className="panel-sidebar-menu__items__single__icon__image"
                  src={item.icon}
                  alt={item.iconAlt}
                />
              </span>
              <span className={`
                panel-sidebar-menu__items__single__title
                ${isActive(`${item.route}`) ? 'panel-sidebar-menu__items__single__link--active__title': ''}
              `}>
                {item.name}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PanelSidebarMenu;

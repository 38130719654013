import ProductRequests from '~/repositories/product';
import * as MessageService from '~/services/message';

import { PRODUCT_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getProductReport = () => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: Array<models.Product> = await ProductRequests.getReport(); 

    dispatch({
      payload,
      type: PRODUCT_REPORT,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
}
/* tslint:disable:object-literal-sort-keys */
const ptBr = {
  APPLICATION: {
    CURRENCY: 'BRL',
    ERRORS: {
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      INVALID_KEY: 'Key do i18n inválida ou inexistente.',
      INSTANCE: {
        REFRESH_TOKEN: 'Token expirado. Faça Login novamente!',
        LOGIN: 'Faça Login novamente!',
      },
      TOKENS_NOT_SEND: 'Push notification não enviada',
      ENTITY_NOT_FOUND: 'Não foi possível encontrar o item buscado',
      USER_ALREADY_EXISTS: 'Usuário já existe',
    },
    LANG: 'pt-BR',
  },
  COMPONENTS: {
    ADVANCED_FILTER: {
      FILTER: 'Filtrar',
      CLEAN: 'limpar',
    },
    ADVANCED_FILE_UPLOAD: {
      ERROR: 'Por favor insira uma imagem no formato png ou jpg',
    },
    MODAL: {
      OK: {
        LABEL: 'OK',
      },
      CANCEL: {
        LABEL: 'CANCELAR',
      },
    },
    SEARCH_BAR: {
      SEARCH: {
        LABEL: 'o que deseja procurar?',
      },
      STATUS: {
        LABEL: 'STATUS',
      },
      FILTERS: {
        LABEL: 'FILTROS',
      },
    },
    DATA_TABLE_ACTIONS: {
      ADD: {
        LABEL: 'Adicionar',
      },
      EDIT: {
        LABEL: 'Editar',
      },
      VIEW: {
        LABEL: 'Visualizar',
      },
      DELETE: {
        LABEL: 'Deletar',
      },
      PRINT: {
        LABEL: 'Imprimir',
      },
      OPEN: {
        LABEL: 'Abrir',
      },
      HISTORY: {
        LABEL: 'Histórico',
      },
    },
    PANEL_CONTENT_TOP_BAR: {
      PAGES: {
        DASHBOARD: {
          TITLE: 'Dashboard',
          LOGOUT: 'Sair',
        },
      },
    },
  },
  PAGES: {
    AUTH: {
      LOGIN: {
        URL: '/',
        PAGE_TITLE: 'Entrar',
        MESSAGES: {
          LOGOUT: 'Você saiu do sistema.',
          WELCOME: 'Bem vindo(a) novamente.',
          INVALID: 'Email e/ou senha incorretos.',
        },
        FORM: {
          TITLE: 'ENTRAR',
          MESSAGE:
            'Ut sit amet ultricies turpis, sed molestie eros. Praesent magna neque, elementum non semper vitae, vestibulum vitae mi.',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          PASSWORD: {
            LABEL: 'Senha',
            PLACEHOLDER: 'Seu senha segura',
          },
          BUTTON: {
            TEXT: 'Entrar',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Esqueceu sua senha?',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
      PASSWORD_RECOVERY: {
        URL: '/recuperar-senha',
        PAGE_TITLE: 'Recuperar senha',
        MESSAGES: {
          WELCOME: 'Bem vindo(a) novamente.',
          LOGOUT: 'Você saiu do sistema.',
          EMAIL: 'Verifique seu email',
          RECOVERY_SUCCESS: 'Senha alterada com sucesso',
        },
        FORM: {
          TITLE: 'RECUPERAR SENHA',
          MESSAGE:
            'Iremos enviar os próximos passos no e-mail abaixo para prosseguir com a recuperação de sua senha',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          TOKEN: {
            LABEL: 'Código de recuperação',
          },
          PASSWORD: {
            LABEL: 'Sua nova senha',
          },
          CHANGE_PASSWORD: {
            LABEL: 'Alterar senha',
          },
          BUTTON: {
            TEXT: 'Enviar e-mail',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Para voltar para o login,',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
    },
    PANEL: {
      DASHBOARD: {
        TITLE: 'DASHBOARD',

        DETAILS: {
          WELCOME: 'Seja Bem Vindo ',
          TITLE: 'Informações gerais',
          PAGE_DESCRIPTION: 'Visualize alguns dos principais indicadores',
        },
      },

      ADMIN: {
        ROUTE: {
          NAME: 'ADMINISTRADORES',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/administrador/relatorio/detalhes/',
        },
        TITLE: 'ADMINISTRADORES',
        REPORT: {
          TITLE: 'Administradores',
          PAGE_TITLE: 'Administradores',
          PAGE_DESCRIPTION: 'Listagem de administradores',
          PAGE_TITLE_DETAILS: 'Detalhes',

          ADVANCED_FILTER: {
            NAME: 'Nome',
            EMAIL: 'E-mail',
          },

          TABLE: {
            NAME: {
              FIELD: 'name',
              HEADER: 'Nome'
            },
            EMAIL: {
              FIELD: 'email',
              HEADER: 'E-mail',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar administrador',
          TITLE_EDIT: 'Editar administrador',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um administrador',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um administrador',
          SUCCESS_ADD_MESSAGE: 'Administrador criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Administrador editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Administrador removido com sucesso!',
          FORM: {
            ERROR: {
              PASSWORD: 'Verifique se as senhas são iguais e tente novamente!',
              PASSWORD_EMPTY: 'Preencha o campo senha!',
              NAME: 'Preencha o nome!',
              PHONE: 'Preencha um número de telefone!',
              EMAIL: 'Preencha o e-mail!',
            },
            TITLE: 'Detalhes do administrador',
            NAME: {
              LABEL: 'Nome completo',
            },
            EMAIL: {
              LABEL: 'E-mail válido',
            },
            DDI: {
              LABEL: 'DDI'
            },
            PHONE: {
              LABEL: 'Telefone celular válido',
            },
            PASSWORD: {
              LABEL: 'Senha segura',
            },
            PASSWORD_CONFIRMATION: {
              LABEL: 'Digite novamente a senha',
            },
            CHANGE_PASSWORD: {
              LABEL: 'Desejo alterar a senha',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            },
          },
        },
      },

      POSTER: {
        TITLE: 'POSTER',
        REPORT: {
          TITLE: 'Home do Aplicativo',
          PAGE_TITLE: 'Home do Aplicativo',
          PAGE_DESCRIPTION: 'Listagem de itens na Home do Aplicativo',
        },

        BANNER: {
          ROUTE: {
            NAME: 'POSTER',
            REPORT: 'RELATÓRIO',
            REGISTER: 'REGISTRO',
            DETAILS: '/poster/relatorio/banner/detalhes/',
          },
          TITLE: 'BANNERS',
          REPORT: {
            TITLE: 'Banners',
            PAGE_TITLE: 'Banners',
            PAGE_DESCRIPTION: 'Listagem de banners',
            PAGE_TITLE_DETAILS: 'Detalhes',

            TABLE: {
              PICURL: {
                FIELD: 'picUrl',
                HEADER: 'Imagem',
                LABEL: 'Ver prévia do banner',
              },
              ORDER: {
                FIELD: 'order',
                HEADER: 'Ordem de exibição',
              },
              CREATED: {
                FIELD: 'createdAt',
                HEADER: 'Data de cadastro'
              },
              ACTIONS: {
                FIELD: 'actions',
                HEADER: 'Ações'
              },
            },
          },
          DETAILS: {
            UNDEFINED: 'Não definido',
            TITLE_ADD: 'Adicionar banner',
            TITLE_EDIT: 'Editar banner',
            DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um banner',
            DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um banner',
            SUCCESS_ADD_MESSAGE: 'Banner criado com sucesso!',
            SUCCESS_EDIT_MESSAGE: 'Banner editado com sucesso!',
            SUCCESS_REMOVE_MESSAGE: 'Banner removido com sucesso!',
            FORM: {
              ERROR: {
                PICURL: 'Imagem não selecionada',
                CONTENT: 'Preencher contéudo!',
                CONTENT_TYPE: 'Preencher o tipo de contéudo',
              },
              TITLE: 'Detalhes do banner',
              PICURL: {
                LABEL: 'Upload de imagem'
              },
              CONTENT: {
                WEBVIEW: {
                  LABEL: 'URL da Webview',
                },
                COLLECTION: {
                  LABEL: 'Coleção a ser exibida',
                },
                PRODUCT: {
                  LABEL: 'Produto a ser exibido',
                },
              },
              CONTENT_TYPE: {
                LABEL: 'Tipo de contéudo a ser aberto'
              },
              ORDER: {
                LABEL: 'Ordem de exibição',
              },
              BACK: {
                LABEL: 'VOLTAR',
              },
              SUBMIT: {
                LABEL: 'Salvar'
              },
              UPLOAD: {
                LABEL: 'Carregar imagem',
              },
            },
          },
        },

        CATEGORY: {
          ROUTE: {
            NAME: 'POSTER',
            REPORT: 'RELATÓRIO',
            REGISTER: 'REGISTRO',
            DETAILS: '/poster/relatorio/categoria/detalhes/',
          },
          TITLE: 'CATEGORIAS',
          REPORT: {
            TITLE: 'Categorias',
            PAGE_TITLE: 'Categorias',
            PAGE_DESCRIPTION: 'Listagem de categorias',
            PAGE_TITLE_DETAILS: 'Detalhes',

            ADVANCED_FILTER: {
              TITLE: 'Título',
            },

            TABLE: {
              TITLE: {
                FIELD: 'title',
                HEADER: 'Título'
              },
              PICURL: {
                FIELD: 'picUrl',
                HEADER: 'Imagem',
                LABEL: 'Ver prévia da imagem',
              },
              ORDER: {
                FIELD: 'order',
                HEADER: 'Ordem de exibição',
              },
              CREATED: {
                FIELD: 'createdAt',
                HEADER: 'Data de cadastro'
              },
              ACTIONS: {
                FIELD: 'actions',
                HEADER: 'Ações'
              },
            },
          },
          DETAILS: {
            UNDEFINED: 'Não definido',
            TITLE_ADD: 'Adicionar categoria',
            TITLE_EDIT: 'Editar categoria',
            DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma categoria',
            DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma categoria',
            SUCCESS_ADD_MESSAGE: 'Categoria criada com sucesso!',
            SUCCESS_EDIT_MESSAGE: 'Categoria editada com sucesso!',
            SUCCESS_REMOVE_MESSAGE: 'Categoria removida com sucesso!',
            FORM: {
              ERROR: {
                TITLE: 'Preencher título',
                PICURL: 'Imagem não selecionada',
                CONTENT: 'Preencher contéudo!',
                CONTENT_TYPE: 'Preencher o tipo de contéudo',
              },
              TITLE: 'Detalhes da categoria',
              SUBJECT: {
                LABEL: 'Título'
              },
              PICURL: {
                LABEL: 'Upload de imagem'
              },
              CONTENT: {
                WEBVIEW: {
                  LABEL: 'URL da Webview',
                },
                COLLECTION: {
                  LABEL: 'Coleção a ser exibida',
                },
                PRODUCT: {
                  LABEL: 'Produto a ser exibido',
                },
              },
              CONTENT_TYPE: {
                LABEL: 'Tipo de contéudo a ser aberto'
              },
              ORDER: {
                LABEL: 'Ordem de exibição',
              },
              BACK: {
                LABEL: 'VOLTAR',
              },
              SUBMIT: {
                LABEL: 'Salvar'
              },
              UPLOAD: {
                LABEL: 'Carregar imagem',
              },
            },
          },
        },

        COUNTRY: {
          ROUTE: {
            NAME: 'POSTER',
            REPORT: 'RELATÓRIO',
            REGISTER: 'REGISTRO',
            DETAILS: '/poster/relatorio/pais/detalhes/',
          },
          TITLE: 'PAÍS',
          REPORT: {
            TITLE: 'Países',
            PAGE_TITLE: 'Países',
            PAGE_DESCRIPTION: 'Listagem de países',
            PAGE_TITLE_DETAILS: 'Detalhes',

            ADVANCED_FILTER: {
              TITLE: 'Título',
            },

            TABLE: {
              TITLE: {
                FIELD: 'title',
                HEADER: 'Título'
              },
              PICURL: {
                FIELD: 'picUrl',
                HEADER: 'Imagem',
                LABEL: 'Ver prévia da imagem',
              },
              ORDER: {
                FIELD: 'order',
                HEADER: 'Ordem de exibição',
              },
              CREATED: {
                FIELD: 'createdAt',
                HEADER: 'Data de cadastro'
              },
              ACTIONS: {
                FIELD: 'actions',
                HEADER: 'Ações'
              },
            },
          },
          DETAILS: {
            UNDEFINED: 'Não definido',
            TITLE_ADD: 'Adicionar país',
            TITLE_EDIT: 'Editar país',
            DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um país',
            DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um país',
            SUCCESS_ADD_MESSAGE: 'País criado com sucesso!',
            SUCCESS_EDIT_MESSAGE: 'País editado com sucesso!',
            SUCCESS_REMOVE_MESSAGE: 'País removido com sucesso!',
            FORM: {
              ERROR: {
                TITLE: 'Preencher título',
                PICURL: 'Imagem não selecionada',
                CONTENT: 'Preencher contéudo!',
                CONTENT_TYPE: 'Preencher o tipo de contéudo',
              },
              TITLE: 'Detalhes do país',
              SUBJECT: {
                LABEL: 'Título'
              },
              PICURL: {
                LABEL: 'Upload de imagem'
              },
              CONTENT: {
                WEBVIEW: {
                  LABEL: 'URL da Webview',
                },
                COLLECTION: {
                  LABEL: 'Coleção a ser exibida',
                },
                PRODUCT: {
                  LABEL: 'Produto a ser exibido',
                },
              },
              CONTENT_TYPE: {
                LABEL: 'Tipo de contéudo a ser aberto'
              },
              ORDER: {
                LABEL: 'Ordem de exibição',
              },
              BACK: {
                LABEL: 'VOLTAR',
              },
              SUBMIT: {
                LABEL: 'Salvar'
              },
              UPLOAD: {
                LABEL: 'Carregar imagem',
              },
            },
          },
        },

        CONTENT: {
          OPTIONS: {
            WEBVIEW: 'Webview',
            COLLECTION: 'Coleção',
            PRODUCT: 'Produto',
          },
        }
      },

      PUSH: {
        ROUTE: {
          NAME: 'PUSH NOTIFICATIONS',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/push/relatorio/detalhes/',
        },
        TITLE: 'PUSH NOTIFICATIONS',
        REPORT: {
          TITLE: 'Push Notifications',
          PAGE_TITLE: 'Push Notifications',
          PAGE_DESCRIPTION: 'Listagem de push notifications',
          PAGE_TITLE_DETAILS: 'Detalhes',

          ADVANCED_FILTER: {
            TITLE: 'Título',
            SENDDATE: 'Data de envio',
          },

          TABLE: {
            TITLE: {
              FIELD: 'title',
              HEADER: 'Titulo'
            },
            SENDDATE: {
              FIELD: 'sendDate',
              HEADER: 'Data de envio',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            },
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar push notification',
          TITLE_EDIT: 'Editar push notification',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma push notification',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma push notification',
          SUCCESS_ADD_MESSAGE: 'Notificação criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Notificação editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Notificação removido com sucesso!',
          FORM: {
            ERROR: {
              TITLE: 'Preencha o título!',
              BODY: 'Preencha a descrição!',
              SENDDATE: 'Preencha o data de envio!',
            },
            TITLE: 'Detalhes da push notification',
            SUBJECT: {
              LABEL: 'Título',
            },
            BODY: {
              LABEL: 'Descrição',
            },
            SENDDATE: {
              LABEL: 'Data de envio',
              PLACEHOLDER: 'dd/mm/aaaa hh:mm',
            },
            IS_SCHEDULE: {
              LABEL: 'Agendar?',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      NOT_FOUND: {
        TITLE: 'Erro 404',
        PAGE_DESCRIPTION:
          'Parece que não encontramos a página solicitada.\nVerifique a url digitada e tente novamente.',
      },
    },
  },

  SHARED: {
    CURRENCY_SYMBOL: 'R$',
    SELECT_SOMETHING: 'Selecione...',
    ADD_NEW: 'Adicionar',
    IMPORT_NEW: 'Importar CSV',
    SAVE: 'Salvar',
    ADD_ID: 'registro',
    CANCEL: 'Cancelar',
    DAY: 'Dia',
    DELETE_ACTION: {
      TITLE: 'Deseja realmente excluir esse item?',
      CONTENT: 'Esta ação é irreversível',
    }
  },
};

export default ptBr;

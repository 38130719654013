export const INCREASE_LOADING = 'INCREASE_LOADING';
export const DESCREASE_LOADING = 'DESCREASE_LOADING';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ME = 'AUTH_ME';
export const AUTH_CHECK_LOGGED = 'AUTH_CHECK_LOGGED';

export const PUSH_NOTIFICATION_REPORT = 'PUSH_NOTIFICATION_REPORT';
export const PUSH_NOTIFICATION_DETAIL = 'PUSH_NOTIFICATION_DETAIL';

export const USER_REPORT = 'USER_REPORT';
export const USER_DETAIL = 'USER_DETAIL';

export const BANNER_REPORT = 'BANNER_REPORT';
export const BANNER_DETAIL = 'BANNER_DETAIL';
export const BANNER_COUNT = 'BANNER_COUNT';

export const CATEGORY_REPORT = 'CATEGORY_REPORT';
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';
export const CATEGORY_COUNT = 'CATEGORY_COUNT';

export const COUNTRY_REPORT = 'COUNTRY_REPORT';
export const COUNTRY_DETAIL = 'COUNTRY_DETAIL';
export const COUNTRY_COUNT = 'COUNTRY_COUNT';

export const PRODUCT_REPORT = 'PRODUCT_REPORT';
export const COLLECTION_REPORT = 'COLLECTION_REPORT';